import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Info from '@material-ui/icons/Info';
import ButtonMU from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { favicon } from '../utils.js'
import ActuList from './ActuList.js'

const styles = {
  icon: {
    width: 32,
    height: 32
  },
  tab: {
    minWidth: 100,
    width: 100,
  }
}

const editeurs = [
  'Le Monde',
  'Ouest-France',
  '20 Minutes',
  'Franceinfo',
  'Bfmtv.Com',
  'Le Figaro',
  'Le Parisien',
  'Ladepeche.Fr',
  'Sud Ouest',
  'Midi Libre',
  'La Voix Du Nord',
  "L'Independant",
  "Les Echos"
]

class ActusEditeur extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ tab: newValue });
  };

  render = () => {
    const { articles, classes, isAdmin } = this.props;
    const { tab } = this.state;
    const tabArticles = articles.filter(a => a.editeur === editeurs[tab]);

    return (
      <div className='article-container'>
        <Paper style={{margin: '0 auto', width: 1300, marginBottom: 20, marginLeft: -70}}>
          <Tabs
            value={tab}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {
              Object.keys(favicon).map((editeur) => {
                return <Tab classes={{ root: classes.tab }} id={editeur} icon={<img className={classes.icon} alt="test avatar" src={favicon[editeur]}  /> } />
              })
            }
        </Tabs>
      </Paper>
      <div className="cards-container">
        <ActuList articles={tabArticles} />
      </div>
    </div>
  )
  }
}

ActusEditeur.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActusEditeur);
