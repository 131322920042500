import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../config';

import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Info from '@material-ui/icons/Info';
import ButtonMU from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Datapoint from './sub_components/Datapoint';
import HC_exporting from 'highcharts/modules/exporting'
import HC_offlineExporting from 'highcharts/modules/offline-exporting'
import Edit from '@material-ui/icons/Edit'
import Visibility from '@material-ui/icons/Visibility'

import ActuList from './sub_components/ActuList.js'
import ActusEditeur from './sub_components/ActusEditeur.js'
import ActusClusters from './sub_components/ActusClusters.js'
import ActusEditeurTime from './sub_components/ActusEditeurTime.js'

import { customColor, saveColumnSetup, displayScore } from './utils';

const styles = theme => ({
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  },
  legend: {
    position: 'absolute',
    top: 40,
    left: 220,
    width: 200,
    display: 'flex',
    flexDirection: 'column',
  },
  recentArticle: {
    'border-left': '4px solid #70C28B',
    marginTop: 10,
    marginBottom: 10,
    position: 'relative',
    overflow: 'visible'
  },
  liveArticle: {
    'border-left': '4px solid #DD4B44',
    marginBottom: 10,
    position: 'relative',
    overflow: 'visible'
  },
  infos: {
    "font-size": 11,
    fontWeight: 700,
    "display": "flex",
    alignItems: "center"
  },
  live: {
    color: '#DD4B44'
  },
  dateSelector: {
    position: 'absolute',
    top: 200,
    right: 20,
    width: 150,
    padding: 5
  },
  daySelector: {
    position: 'absolute',
    top: 40,
    right: 20,
    width: 150,
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
  }
});

const formatDate = (a, lenToCut) => {
  if (lenToCut === 9)
    return moment(a, 'YYYY-MM-DD HH').format('D/M')
  return moment(a, 'YYYY-MM-DD HH').format('H[h]')
}

const editeurs = [
  'Tous',
  'Le Monde',
  'Ouest-France',
  '20 Minutes',
  'Franceinfo',
  'Bfmtv.Com',
  'Le Figaro',
  'Le Parisien',
  'Ladepeche.Fr',
  'Sud Ouest',
  'Midi Libre',
  'La Voix Du Nord',
  "L'Independant",
  "Les Echos"
]

class Actus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: 'false',
      articles: [],
      tab: 0,
      rangeStart: moment().subtract(4, 'hours'),
      rangeEnd: moment(),
      period: '4h',
      compareDate: 'firstLocalView',
      compareDay: moment().format('YYYY-MM-DD'),
      editeur: "Tous"
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ tab: newValue });
  };

  handleChangeDay = (event) => {
    if (event.target.value !== moment().format('YYYY-MM-DD')) {
      let rangeStart = moment(event.target.value, 'YYYY-MM-DD').startOf('day')
      let rangeEnd = moment(event.target.value, 'YYYY-MM-DD').endOf('day')
      this.setState({compareDay: event.target.value, period: 'jour', rangeStart, rangeEnd}, () => { this.getData() })
    }
    else {
      let rangeStart = moment().startOf('day')
      let rangeEnd = moment()
      this.setState({compareDay: event.target.value, rangeStart, rangeEnd}, () => { this.getData() })
    }
  }

  handleChangePeriod = (event, newValue) => {
    const newPeriod = event.target.value
    if (newPeriod === 'live')
      this.setState({period: newPeriod, rangeStart: moment().subtract(30, 'minutes'), rangeEnd: moment()}, () => { this.getData() })
    else if (newPeriod === '4h')
      this.setState({period: newPeriod, rangeStart: moment().subtract(4, 'hours'), rangeEnd: moment()}, () => this.getData())
    else if (newPeriod === 'jour') {
      let rangeEnd = moment()
      if (this.state.compareDay !== moment().format('YYYY-MM-DD'))
        rangeEnd = moment(compareDay, 'YYYY-MM-DD').endOf('day')
      this.setState({period: newPeriod, rangeStart: moment(this.state.compareDay, 'YYYY-MM-DD').startOf('day'), rangeEnd: rangeEnd}, () => this.getData())
    }
  };

  handleChangeDate = (event, newValue) => {
    this.setState({compareDate: event.target.value}, () => { this.getData()})
  };

  handleChangeEditeur = (event) => {
    this.setState({editeur: event.target.value})
  };

  getData = async () => {
    await this.setState({render: false})
    const fromDate = this.state.rangeStart.format('YYYY-MM-DDTHH:mm');
    const toDate = this.state.rangeEnd.format('YYYY-MM-DDTHH:mm');
    const compareDate = this.state.compareDate;
    axios.post(`${config.backEndServer}/api/article/flux`, { ...this.props.query, fromDate, toDate, compareDate, dataToGet: this.props.dataToGet }
    , {withCredentials: true, timeout: 60000 })
      .then((res) => {
        this.setState({ render: 'true', articles: res.data });
      })
      .catch(err => {
        this.setState({ render: 'timeout'});
      });
  };

  componentDidMount = () => {
    this.getData();
  }

  render = () => {
    const { classes, isAdmin, displaySelect, onLocalHome, dataToGet } = this.props;
    const { articles, tab, period, rangeStart, rangeEnd, compareDate, compareDay } = this.state;
    if (this.state.render === 'true') {
      const { editeur } = this.state;
      let openTab

      if (tab === 0)
        openTab = (<div className="cards-container"><ActuList articles={articles} rangeStart={rangeStart} rangeEnd={rangeEnd} dataToGet={dataToGet} /></div>)
      else if (tab === 1)
        openTab = (<ActusEditeurTime articles={articles} rangeStart={rangeStart} rangeEnd={rangeEnd} dataToGet={dataToGet} />)
      else if (tab === 2)
        openTab = (<ActusEditeur articles={articles} rangeStart={rangeStart} rangeEnd={rangeEnd} dataToGet={dataToGet} />)
      else if (tab === 3)
        openTab = (<ActusClusters articles={articles} rangeStart={rangeStart} rangeEnd={rangeEnd} editeur={editeur} dataToGet={dataToGet} titre={this.props.title} />)

      return (
        <div className='full-container'>
        <h1> Actus chaudes {this.props.title}</h1>
        <Paper style={{width: 660, margin: '0 auto', marginBottom: 80}}>
          <Tabs
            value={tab}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab id="live" label="Dernieres sorties" />
            <Tab id="groupedTime" label="Timeline Par Editeur" />
            <Tab id="grouped" label="Par Editeur" />
            <Tab id="cluster" label="Par Sujet" />
            <Tab id="cluster" label="Timeline Par Sujet" />
          </Tabs>
        </Paper>

        <div className={classes.daySelector}>
          <FormControl sx={{ m: 1}}>
            <TextField
              id="to-date"
              label="Jour"
              type="date"
              onChange={this.handleChangeDay}
              value={compareDay}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              // variant="filled"
            />
          </FormControl>

          { compareDay === moment().format('YYYY-MM-DD') &&
            <FormControl sx={{ m: 1}} margin="normal">
              <InputLabel id="demo-simple-select-helper-label">Période</InputLabel>
              <Select
                id="demo-simple-select-helper"
                value={period}
                label="Periode"
                onChange={this.handleChangePeriod}
              >
                <MenuItem value={'live'}>Live</MenuItem>
                <MenuItem value={'4h'}>4 heures</MenuItem>
                <MenuItem value={'jour'}>Journée</MenuItem>
              </Select>
            </FormControl>
          }
          {
            tab === 3 &&
            <FormControl sx={{ m: 1}} margin="normal">
              <InputLabel id="demo-simple-select-helper-label">Editeur</InputLabel>
              <Select
                id="demo-simple-select-helper"
                value={editeur}
                label="Editeur"
                onChange={this.handleChangeEditeur}
              >
                {editeurs.map(i => {
                  return <MenuItem key={i} value={i}>{i}</MenuItem>
                })}
              </Select>
            </FormControl>
          }
        </div>
      {/*<FormControl className={classes.dateSelector} sx={{ m: 1}}>
        <InputLabel id="demoabel">Date de référence</InputLabel>
        <Select
          id="dem-helper"
          value={compareDate}
          label="Date"
          onChange={this.handleChangeDate}
        >
          <MenuItem value={'firstLocalView'}>Date d'apparition à la Une</MenuItem>
          <MenuItem value={'lastLocalView'}>Date de dernière vue à la Une</MenuItem>
        </Select>
    </FormControl>
    */}
        <div className={classes.legend}>
          <h4>Légende</h4>
          <div className={classes.infos}><strong style={{color: '#57AAD9'}}>14:07</strong>: Date de première vue {this.props.title}</div>
          <div className={classes.infos}><Edit />Date sitemap de l'article</div>
          <div className={classes.infos}><Visibility />Dernière vue {this.props.title}</div>
          <div className={classes.infos}><Visibility className={classes.live} />Actuellement visible {this.props.title}</div>
        </div>
        {openTab}
      </div>
    )
    } else if (this.state.render === 'timeout') {
      return (
        <div className={classes.filler} >
          <h3>Trop de résultats, délais de chargement expiré. Veuillez sélectionner des paramètres de filtre plus spécifiques.</h3>
        </div>
      )
    }
    else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      )
    }
  };
}


Actus.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Actus);
