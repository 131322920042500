import React, { Component } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';

import ButtonMU from '@material-ui/core/Button';

import config from '../config';
import Editeurs from './Editeurs';
import Articles from './Articles';
import Cluster from './Cluster';
import ClusterList from './ClusterList';
import NewsboxList from './NewsboxList';
import SelectClusterList from './SelectClusterList';
import BertList from './BertList';
import SuiviGeneral from './SuiviGeneral';
import Article from './Article';
import Theme from './Theme';
import Admin from './Admin';
import Report from './Report';
import SitemapsStates from './SitemapsStates';
import Sitemaps from './Sitemaps';
import Actus from './Actus';
import Navbar from './sub_components/Navbar';
import Selector from './sub_components/Selector';
import PartageFb from './PartageFb';
import Applenews from './Applenews';
import Reporting from './Reporting';
import Cea from './Cea';
import CeaArticle from './CeaArticle';
import Sujets from './Sujets';
import AnalyseArticles from './AnalyseArticles';
import { socket } from "./socketIo";
import SocketToast from './sub_components/SocketToast';
import './App.css';

import defaults from './defaults';

class Dashboard extends Component {
  constructor() {
    super();

    this.resetLocalStorage();

    this.state = {
      editeur: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).editeur : '',
      isAdmin: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).isAdmin : '',
      role: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).role : 'normal',
      query: JSON.parse(localStorage.getItem('query')) ? JSON.parse(localStorage.getItem('query')).query :
        {
          fromDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
          toDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm'),
          source: 'tout',
          compareDate: 'all',
          groupe: 'editeur',
          editeur: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).editeur : '',
          clusterCategory: 'all',
          clusterPosition: 'all',
          clusterEntities: '',
          clusterLemmes: '',
          clusterOrphan: false,
          articleCategory: 'all',
          articlePosition: 'all',
          articleEntities: '',
          articleShare: false,
          url: '',
          share: 'facebook'
        },
       socket: socket
    }
  };

  componentDidMount = () => {
    // let articleSettings = localStorage.getItem('articleSettings');
    // let editeurSettings = localStorage.getItem('editeurSettings');
    // if (!articleSettings) {
    let articleSettings = {
      'Editeur': 'true',
      'Partage Facebook': 'true',
      'Score': 'true',
      'Score Flipboard': 'false',
      'Score AppleNews': 'false',
      'Desktop': 'true',
      'Mobile': 'true',
      'Titre': 'true',
      'Top Desktop': 'false',
      'Top Mobile': 'false',
      'Cat Desktop': 'false',
      'Cat Mobile': 'false',
      'Topic Desktop': 'false',
      'Topic Mobile': 'false',
      'Dernière Modification': 'true',
      'id': 'false',
      'Première apparition': 'false',
      'Première exposition': 'false',
      "Heure d'apparition Sitemap": 'false',
      'Partages Sociaux': 'false'
    }
    localStorage.setItem('articleSettings', JSON.stringify(articleSettings));
    // };
    // if (!editeurSettings) {
    let editeurSettings = {
      'Groupe Editeur': 'true',
      'Articles': 'true',
      'Articles Applenews': 'false',
      'Score': 'true',
      'Score Flipboard': 'false',
      'Score AppleNews': 'false',
      'Homepage': 'false',
      'A la une': 'false',
      'Top First': 'false',
      'Top': 'false',
      'Sections': 'false',
      'Cat first': 'false',
      'Cat': 'false',
      'Topic': 'false',
      'Topic First': 'false',
      'Topic (autres sections)': 'false',
      'Homepage': 'false',
      'France': 'false',
      'International': 'false',
      'Economie': 'false',
      'Sciences et Technos': 'false',
      'Divertissement': 'false',
      'Sports': 'false',
      'Sante': 'false'
    }
    localStorage.setItem('editeurSettings', JSON.stringify(editeurSettings));
    // }
  }

  resetLocalStorage = () => {
    var hours = 1.5;
    var now = new Date().getTime();
    var setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('setupTime', now)
    } else {
      if (now - setupTime > hours * 60 * 60 * 1000) {
        localStorage.removeItem('query');
        localStorage.setItem('setupTime', now);
        if (localStorage.getItem("selectedClusters") != null) localStorage.removeItem('selectedClusters');
      }
    }
  }

  newQuery = (query, cb = () => { }) => {
    this.setState({
      query: query,
    }, () => {
      localStorage.setItem('query', JSON.stringify({ query }))
      cb();
    }
    );
  };

  render = () => {
    if (this.state.editeur !== '') {
      return (
        <div className='main-content'>
          { this.state.isAdmin ?
          <SocketToast socket={this.state.socket} /> : null }
          <Navbar isAdmin={this.state.isAdmin} role={this.state.role} />
          <Switch>
            <Route path="/dashboard/suivigeneral" render={(props) => <Selector {...props} newQuery={this.newQuery} query={this.state.query} changeEditeur={true} />} />
            <Route path="/dashboard/editeurs" render={() => <Selector newQuery={this.newQuery} query={this.state.query} />} />
            <Route path="/dashboard/sitemap" render={(props) => <Selector {...props} newQuery={this.newQuery} query={this.state.query} changeEditeur={true} sitemap={true} articleParams={true} />} />
            <Route path="/dashboard/article/url/:url" render={() => <Selector newQuery={this.newQuery} query={this.state.query} />} />
            <Route path="/dashboard/article/:id" render={() => <Selector newQuery={this.newQuery} query={this.state.query} />} />
            <Route path="/dashboard/articles" render={(props) => <Selector {...props} newQuery={this.newQuery} query={this.state.query} changeEditeur={true} articleParams={true} />} />
            <Route path="/dashboard/cluster/:id" render={(props) => <Selector {...props} newQuery={this.newQuery} query={this.state.query} changeEditeur={true} />} />
            <Route path="/dashboard/clusters" render={(props) => <Selector {...props} newQuery={this.newQuery} query={this.state.query} clusterParams={true} />} />
            <Route path="/dashboard/selectclusters" render={(props) => <Selector {...props} newQuery={this.newQuery} query={this.state.query} clusterParams={true} />} />
            <Route path="/dashboard/bert" render={(props) => <Selector {...props} newQuery={this.newQuery} query={this.state.query} clusterParams={true} />} />
            <Route path="/dashboard/couverture" render={(props) => <Selector {...props} newQuery={this.newQuery} query={this.state.query} clusterParams={true} changeEditeur={true} />} />
            <Route path="/dashboard/thematique" render={(props) => <Selector {...props} newQuery={this.newQuery} query={this.state.query} clusterParams={true} changeEditeur={true} />} />
            <Route path="/dashboard/reporting" render={(props) => <Selector {...props} newQuery={this.newQuery} query={this.state.query} clusterParams={true} changeEditeur={true} />} />
            {
              this.state.isAdmin
                ? <Route path="/dashboard/partages" render={(props) => <Selector {...props} newQuery={this.newQuery} query={this.state.query} changeEditeur={true} partageParams={true} changeSource={false} />} />
                : null
            }
            {
              this.state.isAdmin
                ? <Route path="/dashboard/sujets" render={(props) => <Selector {...props} newQuery={this.newQuery} query={this.state.query} sujets={true} changeEditeur={true} />} />
                : null
            }
            {
              this.state.isAdmin
                ? <Route path="/dashboard/cea" render={() => <Selector newQuery={this.newQuery} query={this.state.query} sitemap={true} />} />
                : null
            }
            {
              this.state.isAdmin
                ? <Route path="/dashboard/ceaarticle" render={() => <Selector newQuery={this.newQuery} query={this.state.query} />} />
                : null
            }
            {
              this.state.isAdmin
                ? <Route path="/dashboard/applenews" render={(props) => <Selector {...props} newQuery={this.newQuery} query={this.state.query} changeEditeur={true} changeSource={false} />} />
                : null
            }
            {
              this.state.isAdmin
                ? <Route path="/dashboard/newsbox" render={(props) => <Selector {...props} newQuery={this.newQuery} query={this.state.query} clusterParams={true} changeEditeur={true} />} />
                : null
            }
            {
              this.state.isAdmin
                ? <Route path="/dashboard/analyse_articles" render={(props) => <Selector {...props} newQuery={this.newQuery} query={this.state.query} zoneParams={true} />} />
                : null
            }
          </Switch>
          <Switch>
            <Redirect exact path="/dashboard" to="/dashboard/suivigeneral" />
            <Route path="/dashboard/articles" component={() => <Articles query={this.state.query} changeEditeur={true} />} />
            <Route path="/dashboard/couverture" component={(props) => <ClusterList {...props} query={this.state.query} newQuery={this.newQuery} coverage={true} />} />
            <Route path="/dashboard/sitemap" component={() => <Sitemaps query={this.state.query} />} />
            <Route path="/dashboard/clusters" component={(props) => <ClusterList {...props} query={this.state.query} newQuery={this.newQuery} />} />
            <Route path="/dashboard/editeurs" component={(props) => <Editeurs {...props} query={this.state.query} newQuery={this.newQuery} />} />
            <Route path="/dashboard/suivigeneral" component={() => <SuiviGeneral query={this.state.query} newQuery={this.newQuery} isAdmin={this.state.isAdmin} />} />
            <Route path="/dashboard/article/url/:url" component={(props) => <Article {...props} query={this.state.query} newQuery={this.newQuery} />} />
            <Route path="/dashboard/article/:id" component={(props) => <Article {...props} query={this.state.query} newQuery={this.newQuery} />} />
            <Route path="/dashboard/clusters" component={(props) => <ClusterList {...props} query={this.state.query} newQuery={this.newQuery} />} />
            <Route path="/dashboard/cluster/:id" component={(props) => <Cluster {...props} query={this.state.query} newQuery={this.newQuery} isAdmin={this.state.isAdmin} />} />
            <Route path="/dashboard/selectclusters" component={(props) => <SelectClusterList {...props} query={this.state.query} newQuery={this.newQuery} />} />
            <Route path="/dashboard/thematique" component={(props) => <Theme {...props} query={this.state.query} newQuery={this.newQuery} isAdmin={this.state.isAdmin} socket={this.state.socket} />} />
            <Route path="/dashboard/reporting" component={(props) => <Reporting {...props} query={this.state.query} newQuery={this.newQuery} socket={this.state.socket} />} />
            {
              this.state.isAdmin
                ? <Route path="/dashboard/partages" component={(props) => <PartageFb {...props} query={this.state.query} changeEditeur={true} />} />
                : null
            }
            {
              this.state.isAdmin
                ? <Route path="/dashboard/sujets" component={(props) => <Sujets {...props} query={this.state.query} newQuery={this.newQuery} coverage={true} />} />
                : null
            }
            {
              this.state.isAdmin
                ? <Route path="/dashboard/cea" component={(props) => <Cea {...props} query={this.state.query} newQuery={this.newQuery} />} />
                : null
            }
            {
              this.state.isAdmin
                ? <Route path="/dashboard/ceaarticle" component={(props) => <CeaArticle {...props} query={this.state.query} newQuery={this.newQuery} />} />
                : null
            }
            {
              this.state.isAdmin
                ? <Route path="/dashboard/applenews" component={(props) => <Applenews {...props} query={this.state.query} changeEditeur={true} newQuery={this.newQuery} />} />
                : null
            }
            {
              this.state.isAdmin
                ? <Route exact path="/dashboard/bert" component={(props) => <BertList {...props} query={this.state.query} newQuery={this.newQuery} />} />
                : null
            }
            {
              this.state.isAdmin
                ? <Route exact path="/dashboard/admin" component={() => <Admin />} />
                : null
            }
            {
              this.state.isAdmin
                ? <Route exact path="/dashboard/admin/sitemaps" component={() => <SitemapsStates displayDaily={true} />} />
                : null
            }
            {
              this.state.isAdmin
                ? <Route exact path="/dashboard/admin/report" component={() => <Report />} />
                : null
            }
            {
              this.state.isAdmin
                ? <Route path="/dashboard/newsbox" component={(props) => <NewsboxList {...props} query={this.state.query} newQuery={this.newQuery} coverage={true} />} />
                : null
            }
            {
              this.state.isAdmin
                ? <Route path="/dashboard/analyse_articles" component={(props) => <AnalyseArticles {...props} query={this.state.query} newQuery={this.newQuery} coverage={true} />} />
                : null
            }
            {
              this.state.isAdmin || this.state.role === 'actus'
                ? <Route path="/dashboard/actus" component={() => <Actus query={this.state.query} title="à la Une" dataToGet="dateAlaune" />} />
                : null
            }
            {
              this.state.isAdmin || this.state.role === 'actus'
                ? <Route path="/dashboard/actushome" component={() => <Actus query={this.state.query} title="en Home" dataToGet="dateEnHome" />} />
                : null
            }
          </Switch>
        </div>
      );
    } else {
      return (
        <Redirect to="/login" />
      )
    }
  };
}

export default Dashboard;
