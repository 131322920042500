import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import axios from 'axios';
import moment from 'moment';
import config from '../../config';

import ButtonMU from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ApiStatus from './ApiStatus';

const drawerWidth = 160;

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#57AAD9',
    textColor: 'white',
    // borderRight: 'none',
    // boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'
  },
  list: {
    flex: '2 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center'
  },
  listitem: {
    fontSize: 16,
    fontWeight: 700,
    color: '#ffffff'
  }
});

class ListItemLink extends React.Component {
  renderLink = itemProps => <NavLink to={this.props.to} {...itemProps} />;

  render() {
    const { icon, primary, className } = this.props;
    return (
      <ListItem button component={this.renderLink}>
        {/*<ListItemIcon>{icon}</ListItemIcon> */}
        <ListItemText classes={{ primary: className }} primary={primary} />
      </ListItem>
    );
  }
}

class Navbar extends Component {
  constructor(props) {
    super(props);
  }

  Logout = (e) => {
    e.preventDefault()
    axios.get(`${config.backEndServer}/logout`, { withCredentials: true })
      .then((res) => {
        if (res.data.msg && res.data.msg === 'OK') {
          this.setState({ editeur: '', rows: [] }, () => {
            localStorage.clear(); // clearing the local storage
            window.location.reload(); // refreshing the page with the new local storage
          })
        }
      })
      .catch(e => console.log('logout', e))
  }

  render = () => {
    const { classes } = this.props;
    return (
      <Drawer
        variant="permanent"
        anchor="left"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <img src='/logo-eperf-consulting.png' width="160" />
        <List className={classes.list}>
          <ListItemLink className={classes.listitem} to="/dashboard/suivigeneral" primary="Suivi General" />
          <ListItemLink className={classes.listitem} to="/dashboard/thematique" primary="Thématique" />
          <ListItemLink className={classes.listitem} to="/dashboard/couverture" primary="Couverture" />
          <ListItemLink className={classes.listitem} to="/dashboard/sitemap" primary="Sitemap" />
          <ListItemLink className={classes.listitem} to="/dashboard/selectclusters" primary="Clusters" />
          {
            this.props.isAdmin
            ? <ListItemLink className={classes.listitem} to="/dashboard/sujets" primary="Sujets" />
            : null
          }
          {
            (this.props.isAdmin || this.props.role === 'actus')
              ? <ListItemLink className={classes.listitem} to="/dashboard/actus" primary="Actus à la Une" />
              : null
          }
          {
            (this.props.isAdmin || this.props.role === 'actus')
              ? <ListItemLink className={classes.listitem} to="/dashboard/actushome" primary="Actus en Home" />
              : null
          }
          <ListItemLink className={classes.listitem} to="/dashboard/editeurs" primary="Classement Editeurs" />
          <ListItemLink className={classes.listitem} to="/dashboard/articles" primary="Articles" />
          {
            this.props.isAdmin
            ? <ListItemLink className={classes.listitem} to="/dashboard/reporting" primary="Reporting" />
            : null
          }
          {
            this.props.isAdmin
            ? <ListItemLink className={classes.listitem} to="/dashboard/newsbox" primary="Newsbox" />
            : null
          }
          {
            this.props.isAdmin
            ? <ListItemLink className={classes.listitem} to="/dashboard/analyse_articles" primary="Analyse d'articles" />
            : null
          }
          {
            this.props.isAdmin
              ? <ListItemLink className={classes.listitem} to="/dashboard/partages" primary="Partages" />
              : null
          }
          {
            this.props.isAdmin
              ? <ListItemLink className={classes.listitem} to="/dashboard/applenews" primary="AppleNews" />
              : null
          }
          {
            this.props.isAdmin
              ? <ListItemLink className={classes.listitem} to="/dashboard/cea" primary="CEA" />
              : null
          }
          {
            this.props.isAdmin
              ? <ListItemLink className={classes.listitem} to="/dashboard/ceaarticle" primary="CEA divers" />
              : null
          }
          {
            this.props.isAdmin
              ? <ListItemLink className={classes.listitem} to="/dashboard/admin" primary="Admin" />
              : null
          }
          <ListItem button>
            <ListItemText classes={{ primary: classes.listitem }} primary='Logout' onClick={this.Logout} />
          </ListItem>
        </List>
        <ApiStatus />
      </Drawer>
    );
  };
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navbar);
