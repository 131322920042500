import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';

numeral.register('locale', 'fr', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal : function (number) {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol: '€'
    }
});

const replaceCharacters = (string) => {
  const charset = [["é","Ã©"], ["è","Ã¨"],["ï","Ã¯"],["ô","Ã´"],["ç","Ã§"],["ê","Ãª"],["ù","Ã¹"], ["æ", "Ã¦"], ["œ", "Å"], ["ë", "Ã«"], ["ü", "Ã¼"], ["â", "Ã¢"], ["€", "â¬"], ["©", "Â©"], ["¤", "Â¤"], ["'", "â"],["à","Ã"]];
  let finalString = '';
  finalString = string;
  for (let k=charset.length; k--;) {
    if (string.includes(charset[k][1])) {
      finalString = string.split(charset[k][1]).join(charset[k][0]);
      if (string.length == string.indexOf(charset[k][1])+1) {
        finalString = string.split(charset[k][1]).join("é");
      }
    }
  }
  return finalString;
}


const customColor = {
  'desktop': '#044389',
  'mobile': '#FFCC00',
  // 'flipboard': '#DC493A',
  'applenews': '#D3D3D3',
  'cluster': '#ca5c54'
};

const socialColor = {
  'facebook': '#044389',
  'twitter': '#3993f9'
};

const displayScore = (score) => {
  return numeral(score).format('0,0').replace(/,/g, ' ');
}

function shade(color, percent) {
    var f=parseInt(color.slice(1),16),t=percent<0?0:255,p=percent<0?percent*-1:percent,R=f>>16,G=f>>8&0x00FF,B=f&0x0000FF;
    return "#"+(0x1000000+(Math.round((t-R)*p)+R)*0x10000+(Math.round((t-G)*p)+G)*0x100+(Math.round((t-B)*p)+B)).toString(16).slice(1);
}

const displayQuarterColor = {
  score_google_desktop_topic_first: shade(customColor.desktop, -0.40),
  score_google_desktop_topic: shade(customColor.desktop, -0.20),
  score_google_desktop_cat_first: shade(customColor.desktop, 0.20),
  score_google_desktop_cat: shade(customColor.desktop, 0.40),
  score_google_desktop_top_first: shade(customColor.desktop, -0.80),
  score_google_desktop_top: shade(customColor.desktop, -0.60),
  score_google_mobile_webview_top_first: shade(customColor.mobile, -0.80),
  score_google_mobile_webview_top: shade(customColor.mobile, -0.60),
  score_google_mobile_webview_topic_first: shade(customColor.mobile, -0.40),
  score_google_mobile_webview_topic: shade(customColor.mobile, -0.20),
  score_google_mobile_webview_cat_first: shade(customColor.mobile, 0.20),
  score_google_mobile_webview_cat: shade(customColor.mobile, 0.40),
  // score_flipboard_mobile_flipboard_top: shade(customColor.flipboard, -0.20),
  // score_flipboard_mobile_flipboard_top_first: shade(customColor.flipboard, -0.40),
  // score_flipboard_mobile_flipboard_topic: shade(customColor.flipboard, 0.20),
  // score_flipboard_mobile_flipboard_topic_first: shade(customColor.flipboard, 0.40),
}

const displayColor = {
  timestamps_google_desktop_topic_first: shade(customColor.desktop, -0.40),
  timestamps_google_desktop_topic: shade(customColor.desktop, -0.20),
  timestamps_google_desktop_cat_first: shade(customColor.desktop, 0.20),
  timestamps_google_desktop_cat: shade(customColor.desktop, 0.40),
  timestamps_google_desktop_top_first: shade(customColor.desktop, -0.80),
  timestamps_google_desktop_top: shade(customColor.desktop, -0.60),
  timestamps_google_mobile_webview_top_first: shade(customColor.mobile, -0.80),
  timestamps_google_mobile_webview_top: shade(customColor.mobile, -0.60),
  timestamps_google_mobile_webview_topic_first: shade(customColor.mobile, -0.40),
  timestamps_google_mobile_webview_topic: shade(customColor.mobile, -0.20),
  timestamps_google_mobile_webview_cat_first: shade(customColor.mobile, 0.20),
  timestamps_google_mobile_webview_cat: shade(customColor.mobile, 0.40),
  // timestamps_flipboard_mobile_flipboard_top: shade(customColor.flipboard, -0.20),
  // timestamps_flipboard_mobile_flipboard_top_first: shade(customColor.flipboard, -0.40),
  // timestamps_flipboard_mobile_flipboard_topic: shade(customColor.flipboard, 0.20),
  // timestamps_flipboard_mobile_flipboard_topic_first: shade(customColor.flipboard, 0.40),
  'Google Desktop Topic': shade(customColor.desktop, 0.20),
  'Google Desktop Sections': shade(customColor.desktop, -0.20),
  'Google Desktop A la une': shade(customColor.desktop, -0.40),
  'Google Mobile Topic': shade(customColor.mobile, 0.20),
  'Google Mobile Sections': shade(customColor.mobile, -0.20),
  'Google Mobile A la une': shade(customColor.mobile, -0.40),
  // 'Flipboard Mobile Topic': shade(customColor.flipboard, 0.20),
  // 'Flipboard Mobile 1': shade(customColor.flipboard, -0.20),
  // 'Flipboard Mobile A la une': shade(customColor.flipboard, -0.40),
  'Google Desktop': customColor.desktop,
  'Google Mobile': customColor.mobile,
  // 'Flipboard Mobile': customColor.flipboard,
  'Apple News': customColor.applenews
}

const getQuarterColors = (dataChart) => {
  return dataChart.map(d => displayQuarterColor[d.name]);
}

const splitNumbers = (x) => {
  if (typeof x === 'number')
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return x;
};

const categoriesOptions = {
  homepage: 'A la Une' ,
  france: 'France' ,
  international: 'International',
  'économie': 'Economie' ,
  'science_high-tech': 'Science',
  culture: 'Culture' ,
  sports: 'Sport' ,
  'santé': 'Sante'
}

const getDisplayColors = (timers) => {
  return timers.map(x => displayColor[x.timestamps]);
}

const saveColumnSetup = (table, changedColumn, action) => {
  const settings = JSON.parse(localStorage.getItem(table));
  if (action === 'remove') {
    settings[changedColumn] = 'false'
  } else if (action === 'add') {
    settings[changedColumn] = 'true'
  }
  localStorage.setItem(table, JSON.stringify(settings));
}

const decode = (string) => {
  let finalString = '';
  try { finalString = decodeURIComponent(escape(string)) }
  catch(error) { finalString = string }
  return finalString;
}

const getTitle = (cluster) => {
  if (cluster.titre_selectionne && cluster.titre_selectionne !== '' && cluster.titre_selectionne !== 'Sans titre')
    return decode(cluster.titre_selectionne)
  return decode(cluster.titre);
}

const dayPicker = (fromDate, toDate) => {
  const end = moment(toDate, 'YYYY-MM-DD');
  let day = moment(fromDate, 'YYYY-MM-DD');
  let entries = [];

  if (end.isBefore(day))
    return [];

  entries.push(day);
  while (day.isBefore(end)) {
    day = day.add(1, 'days');
    entries.push(day);
  }
  entries = [...new Set(entries)];
  return entries.map(day => day.format('YYYY-MM-DD'));
}

function dive(currentKey, into, target) {
    for (var i in into) {
        if (into.hasOwnProperty(i)) {
            var newKey = i;
            var newVal = into[i];

            // if (newKey === 'score' || !newKey.match(/score/)) {
            if (currentKey.length > 0) {
                newKey = currentKey + '.' + i;
            }

            if (typeof newVal === "object") {
                dive(newKey, newVal, target);
            } else {
                target[newKey] = newVal;
            }
            // }
        }
    }
}

function flatten(arr) {
    var newObj = {};
    dive("", arr, newObj);
    return newObj;
}

// let defScores = {
//   "score": 0,
//   "score_google_desktop": 0,
//   "score_google_mobile_webview": 0,
//   "score_flipboard_mobile": 0,
//   "score_applenews": 0,
//   "score_google_desktop_top": 0,
//   "score_google_desktop_cat": 0,
//   "score_google_desktop_topic": 0,
//   "score_google_desktop_homepage": 0,
//   "score_google_desktop_france": 0,
//   "score_google_desktop_international": 0,
//   "score_google_desktop_économie": 0,
//   "score_google_desktop_science_high-tech": 0,
//   "score_google_desktop_culture": 0,
//   "score_google_desktop_sports": 0,
//   "score_google_desktop_santé": 0,
//   "score_google_mobile_webview_top": 0,
//   "score_google_mobile_webview_cat": 0,
//   "score_google_mobile_webview_topic": 0,
//   "score_google_mobile_webview_homepage": 0,
//   "score_google_mobile_webview_france": 0,
//   "score_google_mobile_webview_international": 0,
//   "score_google_mobile_webview_économie": 0,
//   "score_google_mobile_webview_santé": 0,
//   "score_google_mobile_webview_sports": 0,
//   "score_google_mobile_webview_science_high-tech": 0,
//   "score_google_mobile_webview_culture": 0,
//   "score_google_mobile_webview_A la une": 0,
//   "score_google_mobile_webview_Economie": 0,
//   "score_google_desktop_A la une": 0,
//   "score_google_desktop_Economie": 0
// }

let defScores = {
  "score": 0,
  "score_google_desktop": 0,
  "score_google_mobile_webview": 0,
  // "score_flipboard_mobile": 0,
  "score_applenews": 0,
  "score_google_desktop_top": 0,
  "score_google_desktop_top_first": 0,
  "score_google_desktop_cat": 0,
  "score_google_desktop_cat_first": 0,
  "score_google_desktop_topic": 0,
  "score_google_desktop_topic_first": 0,
  "score_google_mobile_webview_top": 0,
  "score_google_mobile_webview_top_first": 0,
  "score_google_mobile_webview_cat": 0,
  "score_google_mobile_webview_cat_first": 0,
  "score_google_mobile_webview_topic": 0,
  "score_google_mobile_webview_topic_first": 0,
}

const getGraphQuarter = (scores_quarts, fromDate, toDate) => {

  let scores = {...defScores};

  // forces renderinig fromDate toDate even if empty points
  const end = moment(toDate, 'YYYY-MM-DDTHH:mm');
  let quarter = moment(fromDate, 'YYYY-MM-DDTHH:mm');
  let entries = {};

  if (!end.isBefore(quarter)) {
    entries[quarter.format('YYYY-MM-DD HH:mm')] = 0;
    while (quarter.isBefore(end)) {
      quarter = quarter.add(15, 'minutes');
      entries[quarter.format('YYYY-MM-DD HH:mm')] = 0;
    }
  }
  const groupedSingleSeries = {
    score_google_desktop: {...entries},
    score_google_mobile_webview: {...entries},
    // score_flipboard_mobile: {...entries},
    score_applenews: {...entries}
  }

  const graphs = flatten(scores_quarts);
  for (let i in graphs) {
    const scoreType = i.replace(/.+\./, '');
    if (groupedSingleSeries[scoreType]) {
      const time = i.slice(0, 16).replace('.', ' ').replace('_', ':');
      if (time >= moment(fromDate, 'YYYY-MM-DDTHH:mm').subtract(15, 'minutes').format('YYYY-MM-DD HH:mm') && time <= toDate.replace('T', ' ') && graphs[i]) {
        if (groupedSingleSeries[scoreType][time]) {
          groupedSingleSeries[scoreType][time] += graphs[i]
        }
        else {
          groupedSingleSeries[scoreType][time] = graphs[i]
        }
      }
    }
  }

  for (let i in graphs) {
    const scoreType = i.replace(/.+\./, '');
    if (typeof scores[scoreType] !== 'undefined') {
      const time = i.slice(0, 16).replace('.', ' ').replace('_', ':');
      if (time >= moment(fromDate, 'YYYY-MM-DDTHH:mm').subtract(15, 'minutes').format('YYYY-MM-DD HH:mm') && time <= toDate.replace('T', ' ') && graphs[i]) {
        if (i.indexOf('_first') > -1)
          scores[scoreType.replace('_first', '')] += graphs[i]
        else
          scores[scoreType] += graphs[i]
      }
    }
  }

  const dataChart = [
    { name: 'Desktop', data: groupedSingleSeries.score_google_desktop },
    { name: 'Mobile', data: groupedSingleSeries.score_google_mobile_webview },
    // { name: 'Flipboard', data: groupedSingleSeries.score_flipboard_mobile },
    { name: 'AppleNews', data: groupedSingleSeries.score_applenews }
  ];
  return { score: scores.score , dataChart, scores: scores };
}

const getSocialHour = (social_shares_jours, fromDate, toDate) => {
  if (!social_shares_jours)
    return null;

  if (Array.isArray(social_shares_jours))
    social_shares_jours = _.flatten(social_shares_jours);
  else
    social_shares_jours = [social_shares_jours];

  // forces renderinig fromDate toDate even if empty points
  const end = moment(toDate, 'YYYY-MM-DDTHH');
  let quarter = moment(fromDate, 'YYYY-MM-DDTHH');
  let entries = {};
  let hasScore = false;

  if (!end.isBefore(quarter)) {
    entries[quarter.format('YYYY-MM-DD HH')] = 0;
    while (quarter.isBefore(end)) {
      quarter = quarter.add(1, 'hour');
      entries[quarter.format('YYYY-MM-DD HH')] = 0;
    }
  }
  const groupedSingleSeries = {
    social_shares_facebook: {...entries},
    social_shares_twitter: {...entries},
  }


  social_shares_jours.forEach((curr, index) => {
    const graphs = flatten(curr);
    for (let i in graphs) {
      const scoreType = i.replace(/.+\./, '');
      if (groupedSingleSeries[scoreType]) {
        const time = i.slice(0, 13).replace('.', ' ')
        if (typeof entries[time] !== 'undefined') {
          groupedSingleSeries[scoreType][time] += graphs[i]
          // has one entry at least
          if (!hasScore && graphs[i])
            hasScore = true;
        }
      }
    }
  });

  if (!hasScore)
    return null;

  const dataChart = [
    { name: 'Facebook', data: groupedSingleSeries.social_shares_facebook },
    { name: 'Twitter', data: groupedSingleSeries.social_shares_twitter },
  ];
  return dataChart;
}

const getGraphArray = (scores, days, fromDate, toDate, timeframe, clusters={}) => {
  if (timeframe === '$scores_jour')
    return getGraphDayArray(scores, days, fromDate, toDate);
  if (timeframe === '$scores_heures')
    return getGraphHourArray(scores, days, fromDate, toDate);
  if (timeframe === '$scores_quarts')
    return getGraphQuarterArray(scores, days, fromDate, toDate, clusters);
  return { score: 0, dataChart: {} };
}

const getGraphQuarterArray = (array_quarts, days, fromDate, toDate, clusters_quarts) => {
    // forces renderinig fromDate toDate even if empty points
    const end = moment(toDate, 'YYYY-MM-DDTHH:mm');
    let quarter = moment(fromDate, 'YYYY-MM-DDTHH:mm');
    let entries = {};


    if (!end.isBefore(quarter)) {
      entries[quarter.format('YYYY-MM-DD HH:mm')] = 0;
      while (quarter.isBefore(end)) {
        quarter = quarter.add(15, 'minutes');
        entries[quarter.format('YYYY-MM-DD HH:mm')] = 0;
      }
    }
    let groupedSingleSeries = {
      score_google_desktop: {...entries},
      score_google_mobile_webview: {...entries},
      // score_flipboard_mobile: {...entries},
      score_applenews: {...entries}
    }

    if (!array_quarts)
      return   {
        score: 0,
        dataChart: [
          { name: 'Desktop', data: groupedSingleSeries.score_google_desktop, stack: "score_theme" },
          { name: 'Mobile', data: groupedSingleSeries.score_google_mobile_webview, stack: "score_theme" },
          // { name: 'Flipboard', data: groupedSingleSeries.score_flipboard_mobile },
          { name: 'AppleNews', data: groupedSingleSeries.score_applenews, stack: "score_theme" }
        ]
      };

  array_quarts.forEach((curr, index) => {
    let scores_quarts = {
      [days[index]]: curr
    }
    const graphs = flatten(scores_quarts);
    for (let i in graphs) {
      const scoreType = i.replace(/.+\./, '');
      if (groupedSingleSeries[scoreType]) {
        const time = i.slice(0, 16).replace('.', ' ').replace('_', ':');
        if (time >= moment(fromDate, 'YYYY-MM-DDTHH:mm').subtract(15, 'minutes').format('YYYY-MM-DD HH:mm') && time <= toDate.replace('T', ' ') && graphs[i]) {
          if (groupedSingleSeries[scoreType][time]) {
            groupedSingleSeries[scoreType][time] += graphs[i]
          }
          else {
            groupedSingleSeries[scoreType][time] = graphs[i]
          }
        }
      }
    }
  });
  if (Object.keys(clusters_quarts).length > 0) {
    groupedSingleSeries.score = {...entries};
    const graphs = flatten(clusters_quarts);
    for (let i in graphs) {
      const scoreType = i.replace(/.+\./, '');
      if (groupedSingleSeries[scoreType] && scoreType == 'score') {
        const time = i.slice(0, 16).replace('.', ' ').replace('_', ':');
        if (time >= moment(fromDate, 'YYYY-MM-DDTHH:mm').subtract(15, 'minutes').format('YYYY-MM-DD HH:mm') && time <= toDate.replace('T', ' ') && graphs[i]) {
          if (groupedSingleSeries[scoreType][time]) {
            groupedSingleSeries[scoreType][time] += graphs[i]
          } else {
            groupedSingleSeries[scoreType][time] = graphs[i]
          }
        }
      }
    }
  }
  let score = 0;
  Object.keys(groupedSingleSeries).forEach(scoreType => Object.keys(groupedSingleSeries[scoreType]).forEach(entry => score += groupedSingleSeries[scoreType][entry]));
  const dataChart = [
    { name: 'Desktop', data: groupedSingleSeries.score_google_desktop, stack: "score_theme" },
    { name: 'Mobile', data: groupedSingleSeries.score_google_mobile_webview, stack: "score_theme" },
    // { name: 'Flipboard', data: groupedSingleSeries.score_flipboard_mobile },
    { name: 'AppleNews', data: groupedSingleSeries.score_applenews, stack: "score_theme" }
  ];
  Object.keys(clusters_quarts).length > 0 && dataChart.push({ name: 'Cluster sélectionné', data: groupedSingleSeries.score, stack: "score_total" });
  return { score, dataChart };
}

const getGraphDayArray = (array_quarts, days, fromDate, toDate) => {
    let entries = {};
    const end = moment(toDate, 'YYYY-MM-DD');
    let quarter = moment(fromDate, 'YYYY-MM-DD');

    if (!end.isBefore(quarter)) {
      entries[quarter.format('YYYY-MM-DD 00:00')] = 0;
      while (quarter.isBefore(end)) {
        quarter = quarter.add(1, 'days');
        entries[quarter.format('YYYY-MM-DD 00:00')] = 0;
      }
    }

    const groupedSingleSeries = {
      score_google_desktop: {...entries},
      score_google_mobile_webview: {...entries},
      // score_flipboard_mobile: {...entries},
      score_applenews: {...entries}
    }

    if (!array_quarts)
      return   {
        score: 0,
        dataChart: [
          { name: 'Desktop', data: groupedSingleSeries.score_google_desktop },
          { name: 'Mobile', data: groupedSingleSeries.score_google_mobile_webview },
          // { name: 'Flipboard', data: groupedSingleSeries.score_flipboard_mobile },
          { name: 'AppleNews', data: groupedSingleSeries.score_applenews }
        ]
      };

  array_quarts.forEach((curr, index) => {
    for (let i in curr) {
      if (groupedSingleSeries[i]) {
        if (groupedSingleSeries[i][days[index]]) {
          groupedSingleSeries[i][days[index] + ' 00:00'] += curr[i]
        }
        else {
          groupedSingleSeries[i][days[index] + ' 00:00'] = curr[i]
        }
      }
    }
  });
  let score = 0;
  Object.keys(groupedSingleSeries).forEach(scoreType => Object.keys(groupedSingleSeries[scoreType]).forEach(entry => score += groupedSingleSeries[scoreType][entry]));
  const dataChart = [
    { name: 'Desktop', data: groupedSingleSeries.score_google_desktop },
    { name: 'Mobile', data: groupedSingleSeries.score_google_mobile_webview },
    // { name: 'Flipboard', data: groupedSingleSeries.score_flipboard_mobile },
    { name: 'AppleNews', data: groupedSingleSeries.score_applenews }
  ];
  return { score, dataChart };
}
const getGraphHourArray = (array_quarts, days, fromDate, toDate) => {
    // forces renderinig fromDate toDate even if empty points
    const end = moment(toDate, 'YYYY-MM-DDTHH:00');
    let quarter = moment(fromDate, 'YYYY-MM-DDTHH:00');
    let entries = {};


    if (!end.isBefore(quarter)) {
      entries[quarter.format('YYYY-MM-DD HH:00')] = 0;
      while (quarter.isBefore(end)) {
        quarter = quarter.add(1, 'hours');
        entries[quarter.format('YYYY-MM-DD HH:00')] = 0;
      }
    }
    const groupedSingleSeries = {
      score_google_desktop: {...entries},
      score_google_mobile_webview: {...entries},
      // score_flipboard_mobile: {...entries},
      score_applenews: {...entries}
    }

    if (!array_quarts)
      return   {
        score: 0,
        dataChart: [
          { name: 'Desktop', data: groupedSingleSeries.score_google_desktop },
          { name: 'Mobile', data: groupedSingleSeries.score_google_mobile_webview },
          // { name: 'Flipboard', data: groupedSingleSeries.score_flipboard_mobile },
          { name: 'AppleNews', data: groupedSingleSeries.score_applenews }
        ]
      };

  array_quarts.forEach((curr, index) => {
    let scores_quarts = {
      [days[index]]: curr
    }
    const graphs = flatten(scores_quarts);
    for (let i in graphs) {
      const scoreType = i.replace(/.+\./, '');
      if (groupedSingleSeries[scoreType]) {
        const time = i.slice(0, 13).replace('.', ' ') + ':00';
        if (time >= moment(fromDate, 'YYYY-MM-DDTHH:00').subtract(1, 'hours').format('YYYY-MM-DD HH:00') && time <= toDate.replace('T', ' ') && graphs[i]) {
          if (groupedSingleSeries[scoreType][time]) {
            groupedSingleSeries[scoreType][time] += graphs[i]
          }
          else {
            groupedSingleSeries[scoreType][time] = graphs[i]
          }
        }
      }
    }
  });
  let score = 0;
  Object.keys(groupedSingleSeries).forEach(scoreType => Object.keys(groupedSingleSeries[scoreType]).forEach(entry => score += groupedSingleSeries[scoreType][entry]));
  const dataChart = [
    { name: 'Desktop', data: groupedSingleSeries.score_google_desktop },
    { name: 'Mobile', data: groupedSingleSeries.score_google_mobile_webview },
    // { name: 'Flipboard', data: groupedSingleSeries.score_flipboard_mobile },
    { name: 'AppleNews', data: groupedSingleSeries.score_applenews }
  ];
  return { score, dataChart };
}

const favicon = {
  "Le Monde": "https://www.lemonde.fr/favicon.ico",
  "Ouest-France": "https://media.ouest-france.fr/v1/pictures/c9126ad10fabf9227972c30374c64878-favicon-32x32.png?client_id=cmsfront&sign=20e1769708724621cc3f3fbd7a5ed8e5c746cab611def09c8c3fcdd42c594fc5",
  "20 Minutes": "https://www.20minutes.fr/favicons/favicon.ico",
  "Franceinfo": "https://www.francetvinfo.fr/skin/www/img/favicon/favicon.ico",
  "Bfmtv.Com": "https://bfmtv.com/favicon.png",
  "Le Figaro": "https://www.lefigaro.fr/favicon-32x32.png",
  "Le Parisien": "https://www.leparisien.fr/pf/resources/favicon.ico?d=427",
  "Ladepeche.Fr": "https://www.ladepeche.fr/favicon-32x32.png",
  'Sud Ouest': "https://www.sudouest.fr/so/favicon-32x32.png?v20220817141432",
  'Midi Libre': "https://www.midilibre.fr/favicon-32x32.png",
  'La Voix Du Nord': "https://lvdneng.rosselcdn.net/sites/default/files/mediastore/1590671297_pwa-32.png",
  "L'Independant": "https://www.lindependant.fr/favicon-32x32.png",
  "Les Echos": "/d67e55009861e60edc6d9a309b24387f.png",
}

const getOffset = (date, rangeStart, rangeEnd=moment()) => {
  const totalLength = 980;
  const period = rangeEnd - rangeStart

  return (date - rangeStart) / period * totalLength
}

const getLen = (startDate, endDate, rangeStart, rangeEnd=moment()) => {
  return getOffset(endDate, rangeStart, rangeEnd) - getOffset(startDate, rangeStart, rangeEnd)
}

export { saveColumnSetup, getDisplayColors,  getQuarterColors, customColor, socialColor, displayScore, getTitle, categoriesOptions, getGraphQuarter, getGraphArray, getGraphQuarterArray, getSocialHour, splitNumbers, replaceCharacters, decode, favicon, getOffset, getLen };
